<template>
  <div>
    <base-alerts></base-alerts>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(verifyCode)">
        <base-input
          placeholder="Code"
          type="text"
          name="Verification Code"
          rules="required"
          v-model="pin"
        />
        <div class="button-row">
          <button
            type="submit"
            class="btn btn-primary large"
            :class="processing ? 'processing' : ''"
          >
            {{$t('Continue')}}
          </button>
        </div>
      </b-form>
    </validation-observer>
    <p class="pin-note">
      Didn't get the PIN?
      <a href="javascript:void(0);" @click="resendPIN">{{$t('register.Resend')}}</a>
    </p>
  </div>
</template>

<script>
import verifyEmail from "@/mixin/verifyEmail";
export default {
  mixins: [verifyEmail],
  data() {
    return {
      pin: null,
      processing: false,
    };
  },
  created() {
    // this.resendPIN();
  },
};
</script>
<style scoped lang="scss">
.pin-note {
  padding-top: rem(15px);

  color: var(--textprimary);
  a {
    color: var(--primary);
    font-weight: 700;
  }
}
</style>
